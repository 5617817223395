import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = { class: "col-xl-4" }
const _hoisted_3 = { class: "col-xl-4" }
const _hoisted_4 = { class: "col-xl-4" }
const _hoisted_5 = { class: "row g-5 g-xl-8" }
const _hoisted_6 = { class: "col-xl-4" }
const _hoisted_7 = { class: "col-xl-4" }
const _hoisted_8 = { class: "col-xl-4" }
const _hoisted_9 = { class: "row g-5 g-xl-8" }
const _hoisted_10 = { class: "col-xl-4" }
const _hoisted_11 = { class: "col-xl-4" }
const _hoisted_12 = { class: "col-xl-4" }
const _hoisted_13 = { class: "row g-5 g-xl-8" }
const _hoisted_14 = { class: "col-xl-4" }
const _hoisted_15 = { class: "col-xl-4" }
const _hoisted_16 = { class: "col-xl-4" }
const _hoisted_17 = { class: "row g-5 g-xl-8" }
const _hoisted_18 = { class: "col-xl-4" }
const _hoisted_19 = { class: "col-xl-4" }
const _hoisted_20 = { class: "col-xl-4" }
const _hoisted_21 = { class: "row g-5 g-xl-8" }
const _hoisted_22 = { class: "col-xl-4" }
const _hoisted_23 = { class: "col-xl-4" }
const _hoisted_24 = { class: "col-xl-4" }
const _hoisted_25 = { class: "row g-5 g-xl-8" }
const _hoisted_26 = { class: "col-xl-4" }
const _hoisted_27 = { class: "col-xl-4" }
const _hoisted_28 = { class: "col-xl-4" }
const _hoisted_29 = { class: "row g-5 g-xl-8" }
const _hoisted_30 = { class: "col-xl-4" }
const _hoisted_31 = { class: "col-xl-4" }
const _hoisted_32 = { class: "col-xl-4" }
const _hoisted_33 = { class: "row g-5 g-xl-8" }
const _hoisted_34 = { class: "col-xl-4" }
const _hoisted_35 = { class: "col-xl-4" }
const _hoisted_36 = { class: "col-xl-4" }
const _hoisted_37 = { class: "row g-5 g-xl-8" }
const _hoisted_38 = { class: "col-xl-4" }
const _hoisted_39 = { class: "col-xl-4" }
const _hoisted_40 = { class: "col-xl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MixedWidget1 = _resolveComponent("MixedWidget1")
  const _component_MixedWidget2 = _resolveComponent("MixedWidget2")
  const _component_MixedWidget8 = _resolveComponent("MixedWidget8")
  const _component_MixedWidget9 = _resolveComponent("MixedWidget9")
  const _component_MixedWidget3 = _resolveComponent("MixedWidget3")
  const _component_MixedWidget4 = _resolveComponent("MixedWidget4")
  const _component_MixedWidget5 = _resolveComponent("MixedWidget5")
  const _component_MixedWidget6 = _resolveComponent("MixedWidget6")
  const _component_MixedWidget7 = _resolveComponent("MixedWidget7")
  const _component_MixedWidget10 = _resolveComponent("MixedWidget10")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_MixedWidget1, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "primary"
        })
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_MixedWidget1, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "danger"
        })
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_MixedWidget1, {
          "widget-classes": "card-xl-stretch mb-5 mb-xl-8",
          "widget-color": "success"
        })
      ])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_MixedWidget2, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "info",
          "chart-height": "200",
          "stroke-color": "#4e12c4"
        })
      ]),
      _createVNode("div", _hoisted_7, [
        _createVNode(_component_MixedWidget2, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "danger",
          "chart-height": "200",
          "stroke-color": "#cb1e46"
        })
      ]),
      _createVNode("div", _hoisted_8, [
        _createVNode(_component_MixedWidget2, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "primary",
          "chart-height": "200",
          "stroke-color": "#0078d0"
        })
      ])
    ]),
    _createVNode("div", _hoisted_9, [
      _createVNode("div", _hoisted_10, [
        _createVNode(_component_MixedWidget8, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          image: "media/svg/brand-logos/plurk.svg",
          color: "danger",
          title: "Monthly Subscription",
          date: "Due: 27 Apr 2020",
          progress: "75"
        })
      ]),
      _createVNode("div", _hoisted_11, [
        _createVNode(_component_MixedWidget8, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          image: "media/svg/brand-logos/vimeo.svg",
          color: "primary",
          title: "Monthly Subscription",
          date: "Due: 27 Apr 2020",
          progress: "75"
        })
      ]),
      _createVNode("div", _hoisted_12, [
        _createVNode(_component_MixedWidget8, {
          "widget-classes": "card-xl-stretch mb-5 mb-xl-8",
          image: "media/svg/brand-logos/kickstarter.svg",
          color: "success",
          title: "Monthly Subscription",
          date: "Due: 27 Apr 2020",
          progress: "75"
        })
      ])
    ]),
    _createVNode("div", _hoisted_13, [
      _createVNode("div", _hoisted_14, [
        _createVNode(_component_MixedWidget9, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          image: "media/svg/brand-logos/plurk.svg",
          time: "7 hours ago",
          title: "PitStop - Multiple Email Generator",
          description: "Pitstop creates quick email campaigns.<br/>\n            We help to strengthen your brand<br/>\n            for your every purpose."
        })
      ]),
      _createVNode("div", _hoisted_15, [
        _createVNode(_component_MixedWidget9, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          image: "media/svg/brand-logos/telegram.svg",
          time: "10 days ago",
          title: "ReactJS Admin Theme",
          description: "Pitstop creates quick email campaigns.<br/>\n            We help to strengthen your brand<br/>\n            for your every purpose."
        })
      ]),
      _createVNode("div", _hoisted_16, [
        _createVNode(_component_MixedWidget9, {
          "widget-classes": "card-xl-stretch mb-5 mb-xl-8",
          image: "media/svg/brand-logos/vimeo.svg",
          time: "2 weeks ago",
          title: "KT.com - High Quality Templates",
          description: "Easy to use, incredibly flexible and secure<br/>\n            with in-depth documentation that outlines<br/>\n            everything for you"
        })
      ])
    ]),
    _createVNode("div", _hoisted_17, [
      _createVNode("div", _hoisted_18, [
        _createVNode(_component_MixedWidget3, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "chart-height": "150",
          "chart-color": "primary"
        })
      ]),
      _createVNode("div", _hoisted_19, [
        _createVNode(_component_MixedWidget3, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "chart-height": "150",
          "chart-color": "danger"
        })
      ]),
      _createVNode("div", _hoisted_20, [
        _createVNode(_component_MixedWidget3, {
          "widget-classes": "card-xl-stretch mb-5 mb-xl-8",
          "chart-height": "150",
          "chart-color": "success"
        })
      ])
    ]),
    _createVNode("div", _hoisted_21, [
      _createVNode("div", _hoisted_22, [
        _createVNode(_component_MixedWidget4, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "chart-color": "primary",
          "chart-height": "200",
          "btn-color": "primary"
        })
      ]),
      _createVNode("div", _hoisted_23, [
        _createVNode(_component_MixedWidget4, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "chart-color": "success",
          "chart-height": "200",
          "btn-color": "success"
        })
      ]),
      _createVNode("div", _hoisted_24, [
        _createVNode(_component_MixedWidget4, {
          "widget-classes": "card-xl-stretch mb-5 mb-xl-8",
          "chart-color": "danger",
          "chart-height": "200",
          "btn-color": "danger"
        })
      ])
    ]),
    _createVNode("div", _hoisted_25, [
      _createVNode("div", _hoisted_26, [
        _createVNode(_component_MixedWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ]),
      _createVNode("div", _hoisted_27, [
        _createVNode(_component_MixedWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "chart-color": "success",
          "chart-height": "150"
        })
      ]),
      _createVNode("div", _hoisted_28, [
        _createVNode(_component_MixedWidget5, {
          "widget-classes": "card-xl-stretch mb-5 mb-xl-8",
          "chart-color": "danger",
          "chart-height": "150"
        })
      ])
    ]),
    _createVNode("div", _hoisted_29, [
      _createVNode("div", _hoisted_30, [
        _createVNode(_component_MixedWidget6, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ]),
      _createVNode("div", _hoisted_31, [
        _createVNode(_component_MixedWidget6, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "chart-color": "success",
          "chart-height": "150"
        })
      ]),
      _createVNode("div", _hoisted_32, [
        _createVNode(_component_MixedWidget6, {
          "widget-classes": "card-xl-stretch mb-5 mb-xl-8",
          "chart-color": "danger",
          "chart-height": "150"
        })
      ])
    ]),
    _createVNode("div", _hoisted_33, [
      _createVNode("div", _hoisted_34, [
        _createVNode(_component_MixedWidget7, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "chart-color": "info",
          "chart-height": "150"
        })
      ]),
      _createVNode("div", _hoisted_35, [
        _createVNode(_component_MixedWidget7, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "chart-color": "warning",
          "chart-height": "150"
        })
      ]),
      _createVNode("div", _hoisted_36, [
        _createVNode(_component_MixedWidget7, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ])
    ]),
    _createVNode("div", _hoisted_37, [
      _createVNode("div", _hoisted_38, [
        _createVNode(_component_MixedWidget10, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "chart-color": "info",
          "chart-height": "200"
        })
      ]),
      _createVNode("div", _hoisted_39, [
        _createVNode(_component_MixedWidget10, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "chart-color": "warning",
          "chart-height": "200"
        })
      ]),
      _createVNode("div", _hoisted_40, [
        _createVNode(_component_MixedWidget10, {
          "widget-classes": "card-xl-stretch mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "200"
        })
      ])
    ])
  ], 64))
}