
import { defineComponent } from "vue";

export default defineComponent({
  name: "widget-1",
  components: {},
  props: {
    widgetClasses: String,
    time: String,
    image: String,
    title: String,
    description: String
  }
});
